import React from 'react'
import BodyTitleContent from '../utils/title-content'

const PersonalInformation = () => {
    return (
        <BodyTitleContent heading={"What we refer to as Personal information"}>
                <ol>
                    <li>
                        1. Information that you provide to us when making enquiries, including name, email address, location, age, gender, date of birth, details about your organization or employer, etc.
                    </li>
                    <li>
                        2. Information about your computer and about your visits to and use of our Site (including your IP address, geographical location, browser type and version, operating system, referral source, length of visit and page views).
                    </li>
                    <li>
                        3. Information that you provide to us when browsing through our Site, or that is generated in the course of the use of our Site (including the timing, frequency and pattern of navigation on our Site).
                    </li>
                    <li>
                        4. Information about you that you provide when submitting a job application on our Site.
                    </li>
                    <li>
                        5. Any other personal information that you send to us.
                    </li>
                </ol>
            </BodyTitleContent>
    )
}

export default PersonalInformation
