import React from 'react'
import Layout from "../components/layout"
import PrivacyPolicy from '../components/privacy'
import PageHeader from '../components/utils/page-header'

const Privacy = () => {
    const seo = {
        title: "FourthCanvas Privacy Policy",
        description: "FourthCanvas privacy policy page",
      }
    return (
        <Layout seo={seo}>
            <PageHeader
                 text={
                    <>
                      Privacy Policy.
                    </>
                  }
            />
            <PrivacyPolicy/>
        </Layout>
    )
}

export default Privacy
