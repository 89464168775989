import React from 'react'
import BodyTitleContent from '../utils/title-content'

const InfoGathering = () => {
    return (
        <BodyTitleContent heading={"We gather information about you if you are:"}>
        <>
            <p>
                Our Customer - We may collect and process your personal data if you buy products or services from us online. This is primarily in connection with the supply of goods or services to you.
            </p>
            <p>
                An Applicant - If you fill a form on our Site to apply for a job with us, whether through the Site or otherwise, we will collect and process your personal data in connection with your application.
            </p>
            <p>
                On our mailing list - If you browse our Site, register for updates on our Site, contact us with an enquiry through our Site, submit a complaint through our Site or use any Services available on our Site, we will collect and process your personal data in connection with your interaction with us and our Site.
            </p>
            <p>
                Our employees or personnel - If you (or your organization) supply products or services to us or otherwise partner with us, we may collect and process your personal data in connection with our receipt of those products and services and/or partnership. This may include personal data included in any email or telephone communications or recorded on any document relating to an order for the products or services.
            </p>
            <p>
                        <b>KINDLY NOTE:</b> <br/>
                        - That when you disclose to us the personal information of another person, it is assumed that you have that person's consent to both the disclosure and the processing of that personal information in accordance with this policy.
                    </p>
                    <p>
                        - That this Policy only applies to our use of personal information obtained by us, whether from you directly or from a third party. It does not apply to personal data collected by third parties during your communications with those third parties or your use of their products or services.
                    </p>
                </>     
        </BodyTitleContent>
    )
}

export default InfoGathering
