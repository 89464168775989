import React from 'react'
import BodyTitleContent from '../utils/title-content'

const InformationUsage = () => {
    return (
        <BodyTitleContent heading={"Using Your Personal Information"}>
                <>
                    <p>
                        Personal information submitted to us through our Site will be used for the purposes specified in this policy or on the relevant pages of our Site.
                    </p>
                    <ul className="privacy-list">
                        <li>We may use your personal information to deal with enquiries and complaints made by or about you relating to our Site</li>
                        <li>Enable your use of the services available on our Site;</li>
                        <li>Keep our Site secure and prevent fraud</li>
                        <li>Consider your job application</li>
                        <li>Discharge our regulatory responsibility</li>
                        <li>Send statements, invoices, newsletters, updates and payment information to you</li>
                        <li>Send you email notifications that you have specifically requested</li>
                        <li>Meet other purposes which we may inform you about from time-to-time.</li>
                    </ul><br/>
                    <p>
                        We will not, without your express consent, supply your personal information to any third party for the purpose of their or any other third party's direct marketing.
                    </p>
                </>
        </BodyTitleContent>
    )
}

export default InformationUsage
