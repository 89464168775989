import React from 'react'
import BodyTitleContent from '../utils/title-content'

const DataErasure = () => {
    return (
        <BodyTitleContent heading={"Erasing Your Data"}>
                <>
                    <p>You can ask us to delete or remove your personal data in some circumstances, such as where we no longer need it or where you withdraw your consent (where applicable). If we have shared your personal data with others, we will let them know about the erasure where possible. If you ask us, where it is possible and lawful for us to do so, we will also tell you who we have shared your personal data with so that you can contact them directly.</p>

                    <p>Access: If you ask us, we will confirm whether we are processing your personal data and, if so, provide you with a copy of that personal data (along with certain other details). If you require additional copies, we may charge a reasonable fee for producing those additional copies.</p>

                    <p>Data portability: You have the right, in certain circumstances, to obtain personal data you have provided to us (in a structured, commonly used and machine readable format) and to reuse it elsewhere or to ask us to transfer it to a third party of your choice.</p>

                    <p>Rectification: If the personal data we hold about you is inaccurate or incomplete, you are entitled to have it rectified. If we have shared your personal data with others, we’ll let them know about the rectification where possible. If you ask us, where possible and lawful to do so, we will also tell you who we’ve shared your personal data with so that you can contact them directly.</p>
                   
                    <p>Restrictions on processing: You can ask us to ‘block’ or suppress the processing of your personal data in certain circumstances such as where you contest the accuracy of that personal data or you object to us processing it for a particular purpose. This may not mean that we will stop storing your personal data but, where we do keep it, we will tell you if we remove any restriction that we have placed on your personal data to stop us processing it further. If we’ve shared your personal data with others, we’ll let them know about the restriction where it is possible for us to do so. If you ask us, where it is possible and lawful for us to do so, we’ll also tell you who we’ve shared your personal data with so that you can contact them directly.</p>

                    <p>Objection: You can ask us to stop processing your personal data, and we will do so, if we are:
                    <ul>
                        <li>• relying on our own or someone else’s legitimate interest to process your personal data, except if we can demonstrate compelling legal grounds for the processing; or</li>
                        <li>• processing your personal data for the purposes direct marketing.</li>
                    </ul>
                    </p>

                    <p>Automated decision-making and profiling: You have the right not to be subject to a decision when it is based on automatic processing, including profiling, if it produces a legal effect or similarly significantly affects you, unless such profiling is necessary for the entering into, or the performance of, a contract between you and us.</p>

                    <p>Withdrawal of consent: If we rely on your consent (or explicit consent) as our legal basis for processing your personal data, you have the right to withdraw that consent at any time. You can exercise your right of withdrawal by contacting us using our contact details in the “How to reach us” section below or by using any other opt-out mechanism we may provide, such as an unsubscribe link in an email.</p>
                </>
            </BodyTitleContent>
    )
}

export default DataErasure
