import React from 'react'
import BodyTitleContent from '../utils/title-content'

const Introduction = () => {
    return (
        <BodyTitleContent
            heading={"Introduction"}
            >
                <>
                    <p>
                        We take our brand seriously and extend the same courtesy to all users of www.fourthcanvas.co.
                    </p>

                    <p>
                        Primarily, we have a commitment to protecting the privacy of users of our Site and this informs how we use your personal information. We understand the trust and expectation that influences you to provide your personal information on our website and we treat it as such to ensure a safe online experience for you.
                    </p>

                    <p>
                        We have through this Privacy Policy, explained our approach to any personal data that we might collect from you or which we have obtained about you from a third party and the purposes for which we process your personal data.
                    </p>

                    <p>
                        When we talk about “personal data”, we mean any information which relates to an identified or identifiable living individual. Individuals might be identified by reference to a name, an identification number, location data, an online identifier (such as an IP address) or to other factors that are specific to them, such as their physical appearance.
                    </p>

                    <p>
                        This Privacy Notice informs you of the nature of the personal data about you that is processed by us and how you can request that we delete it, update it, transfer it and/or provide you with access to it.
                    </p>

                    <p>
                        This Privacy Notice sets out your rights in respect of our processing of your personal data. It is intended to assist you in making informed decisions when using the Site and our Services.
                    </p>

                    <p>
                        Kindly peruse the rest of this Privacy Policy as we explain how your personal information is used. By using and continuing on our Site, it is deemed that you agree with all the terms of this policy, including our use of cookies.
                    </p>
                </>
        </BodyTitleContent>
    )
}

export default Introduction
