import React from 'react'
import BodyTitleContent from '../utils/title-content'

const InformationDisclosure = () => {
    return (
        <BodyTitleContent heading={"Disclosing Personal Information"}>
                <>
                <p>
                We may disclose your personal information to [any of our employees, officers, insurers, professional advisers, agents, suppliers or subcontractors] insofar as reasonably necessary for the purposes set out in this policy.
                </p>

                <p>
                We may disclose your personal information to any authorized agency for investigation purposes.
                </p>

                <p>
                We may disclose your personal information:
                <ul className="privacy-list">
                    <li>to the extent that we are required to do so by law;</li>

                    <li>in connection with any ongoing or prospective legal proceedings;</li>

                    <li>in order to establish, exercise or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk);</li>

                    <li>to any person who we reasonably believe may apply to a court or other competent authority for disclosure of that personal information where, in our reasonable opinion, such court or authority would be reasonably likely to order disclosure of that personal information.</li>
                </ul>
                
                </p>

                <p>Except as provided in this policy, we will not provide your personal information to third parties.
                </p>
                </>
            </BodyTitleContent>
    )
}

export default InformationDisclosure
