import React from 'react'
import BodyTitleContent from '../utils/title-content'
import DataErasure from './data-erasure'
import InfoGathering from './info-gathering'
import InformationDisclosure from './information-disclosure'
import InformationUsage from './information-usage'
import Introduction from './introduction'
import PersonalInformation from './personal-information'

const PrivacyPolicy = () => {
    return (
        <>
            <Introduction/>
            <PersonalInformation/>
            <InfoGathering/>
            <InformationUsage/>
            
            <BodyTitleContent 
            heading={"Our Obligations To You"}
            content={`With respect to your personal data being held or used by Fourthcanvas, you have the following rights which are exercisable as required.`}
            />

            <BodyTitleContent 
            heading={"Filling Complaints"}
            content={`If you have a concern about any aspect of our privacy practices, including the way we have handled your personal data, please contact us using the contact details provided in the “How to reach us” section below.`}
            />
            
            <DataErasure/>
            <InformationDisclosure/>

            <BodyTitleContent heading={"Information Protection"} content={`We protect your information by taking appropriate measures to ensure that your personal information disclosed to us is kept secure, accurate, and up to date and kept only for so long as is necessary for the purposes for which it is used.`} />

            <BodyTitleContent heading={"Cookies"} content={`We may use cookies to collect your personal information. Cookies are unique identifiers which are used to customize your website, mobile application or social media site experience. Most web browsers automatically accept cookies, but you can usually change your browser to prevent or notify you whenever you are sent a cookie. This gives you the chance to decide whether or not to accept the cookie. A cookie does not tell us who you are, your email address, or any other personal information. We use the information it provides to help us improve our website, mobile application, social media site and our services, and to provide our visitors with a better visit. Even without accepting a cookie you can still access many of the features on the site. There may, however, be limitations on your use of some site functions.`} />

            <BodyTitleContent heading={"How To Reach Us"} content={"Should you have any enquiries or questions about this Privacy Notice or want to exercise your rights set out in this Privacy Notice, you can contact us by sending an email to legal@fourthcanvas.co."} />
        </>
    )
}

export default PrivacyPolicy
